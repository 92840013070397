import * as React from "react";
import { graphql } from "gatsby";

import Page from "../components/Page";
import { MiddleContainer, ImageContainer } from "../components/content";
import DefaultLayout from "../layouts/default";
import Spacer from "../components/Spacer";
import Gallery from "../components/Gallery";

import { HobbyType } from "../types";

import { MenuProvider } from '../hooks/Menu/Provider';

import { GatsbySharpFluidImage } from "../types";

export const query = graphql`
query AboutPageQuery {
  hobbies: allHobbiesJson {
    nodes {
      id
      title
      image {
        childImageSharp {
          fluid(quality: 90, maxWidth: 642, maxHeight: 642) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  },
  backgroundImage: imageSharp(fluid: {originalName: {eq: "cappucino.JPG"}}) {
    fluid: fluid(quality: 90, maxWidth: 1920, ) {
        ...GatsbyImageSharpFluid_withWebp
    }
  }
}
`;

interface AboutPageProps {
  data: {
    hobbies: {
      nodes: HobbyType[]
    },
    backgroundImage: GatsbySharpFluidImage
  }
}

const AboutPage: React.FC<AboutPageProps> = ({ data }) => {
  return (
    <MenuProvider>
      <DefaultLayout 
        title={"About"} 
        subtitle={["Am Morgen weckt mich ein feiner Espresso aus der Rocket Espresso Kolbenmaschine und nach einem intensiven Arbeitstag finde ich den sportlichen Ausgleich auf dem Rennrad."]}
        backgroundImage={data.backgroundImage}
      >
          <>
            <Page>
              <MiddleContainer serifFont={true} initialLetter={true}>
                <p>
                Hallo, mein Name ist Adrian. Mit der Arbeit identifiziere ich mich sehr, aber dennoch ist mir meine Freizeit wichtig.
                Den Ausgleich zur kopflastigen Arbeit in Projekten zu finden ist mir wichtig - sei es unterwegs mit meinem 64er Käfer, beim Zeichnen oder in der Natur.
                </p>
              </MiddleContainer>
              <Spacer/>  
              <Gallery>
                {data.hobbies.nodes.map((hobby) => ( 
                  <ImageContainer imagePath={hobby.image}/>
                ))}
              </Gallery>
          </Page>       
        </>
      </DefaultLayout>
    </MenuProvider>
  )
};

export default AboutPage;
