import * as React from "react";
import styled from "styled-components";
import { MiddleContainer } from "./content";

const StyledGallery = styled(MiddleContainer)`
    & > * {
        float: left;
        width: 50%;
        
        &:nth-child(2) {
          padding-right: 10px;
        }
        &:nth-child(3) {
          padding-left: 10px;
        }

        &:first-child {
          width: 100%;
          margin-bottom: 20px;
        }

        &:last-child {
          width: 100%;
          margin-top: 20px;
        }
    }
`;

const Gallery: React.FC = ({ children }) => (
  <StyledGallery>{children}</StyledGallery>
);

export default Gallery;
